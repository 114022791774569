export default class NavbarEnum {
    static BODY = 'body'
    static NAVBAR = '[data-element="navbar"]'
    static LOGO = '[data-element="navbar-logo"]'
    static ECOMODE = '[data-id="eco-bar"]'
    static BURGER_MENU_TRIGGER = '[data-trigger="burger-menu-toggle"]'
    static BURGER_MENU_MORE = '[data-trigger="burger-menu-more"]'
    static BURGER_MENU_PREV = '[data-trigger="burger-menu-prev"]'
    static BURGER_SUBMENU = '[data-element="burger-submenu"]'
    static BURGER_COLLAPSE = '[data-trigger="burger-menu-collapse"]'
    static BURGER_LEVEL_3_ITEMS = '[data-element="burger-menu-level-3-items"]'
    static BURGER_SUBMENU_CLOSE = '[data-trigger="burger-menu-submenu-close"]'
    static LANG_SWITCHER = '[data-trigger="lang-switcher"]'
    static LANG_SWITCHER_LIST = '[data-element="lang-switcher-list"]'
}
