/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import HeaderService from './services/header.service'
import LayoutService from './services/layout.service'

document.addEventListener('DOMContentLoaded', () => {
    new HeaderService()
    new LayoutService()

    // Launch animations
    if (!IRISCollectionCustomer.isEcomodeActive) {
        $('body').addClass('custom-animation-start')
    }
})
