import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.lang()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 40 // Navbar default height
            const offsetOnScroll = 40 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
                $(NavbarEnum.BODY).removeClass('lang-switcher-open')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
            }
        })
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                $(NavbarEnum.BODY).removeClass('menu-open')
                $(NavbarEnum.BODY).addClass('menu-close')
                $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
                $(NavbarEnum.BODY).removeClass('has-submenu-open')
            } else {
                $(NavbarEnum.BODY).addClass('menu-open')
                $(NavbarEnum.BODY).removeClass('menu-close')
                $(NavbarEnum.BODY).removeClass('lang-switcher-open')
            }

            //Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
        })

        // Close on escape key
        $(document).on('keyup', (e) => {
            if (e.keyCode === 27) {
                $(NavbarEnum.BODY).removeClass('menu-open')
                $(NavbarEnum.BODY).addClass('menu-close')
                $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
                $(NavbarEnum.BODY).removeClass('has-submenu-open')
                $(NavbarEnum.BODY).removeClass('lang-switcher-open')
            }
        })

        //Open/Close submenu
        $(NavbarEnum.BURGER_MENU_MORE).on('click', function () {
            $(this).next(NavbarEnum.BURGER_SUBMENU).toggleClass('submenu-open')
            $(NavbarEnum.BODY).toggleClass('has-submenu-open')
        })

        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('has-submenu-open')
        })

        $(NavbarEnum.BURGER_SUBMENU_CLOSE).on('click', () => {
            $(NavbarEnum.BODY).removeClass('menu-open')
            $(NavbarEnum.BODY).addClass('menu-close')
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('has-submenu-open')
        })

        // Collapse mobile
        $(NavbarEnum.BURGER_COLLAPSE).on('click', function () {
            $(this).toggleClass('open')
            $(this).next(NavbarEnum.BURGER_LEVEL_3_ITEMS).toggleClass('open')
        })
    }

    static lang() {
        $(NavbarEnum.LANG_SWITCHER).on('click', (event) => {
            $(NavbarEnum.BODY).toggleClass('lang-switcher-open')
        })

        $(document).click((event) => {
            if (!$(event.target).closest(NavbarEnum.LANG_SWITCHER).length) {
                $(NavbarEnum.BODY).removeClass('lang-switcher-open')
            }
        })
    }
}
